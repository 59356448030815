import axios from "axios"
import { useMemo } from "react"
import {
  User,
  Pagination,
  Validation,
  ValidationData,
  ValidationWorklogData,
  LandValidation,
  LandWorklogDetails,
  ServicePeriodCompletionData,
  ServiceDataUpdate,
  LandValidationListingResponse,
  AccountResponse,
  SortField,
  SnowWorklogsResponse,
  OrgUsersResponse,
} from "../components/Validations/interfaces"
import { S3Document } from "../components/Compliance/interfaces"
import { useActiveUser } from "../providers/AuthProvider"

const api_url = process.env.REACT_APP_API_URL as string

export const useGraphQLService = () => {
  const { token } = useActiveUser()

  return useMemo(() => makeGraphQLService(token), [token])
}

export const makeGraphQLService = (token: string) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  })

  return {
    getBids: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `
            query bidsToServiceProdiver{
              bidsToServiceProvider(
                sfid:"${data.sfid}",
                limit: ${data.limit},
                offset: ${data.offset},
                sort: "${data.sort}",
                sortorder: "${data.order}",
                searchText: "${data.search || ""}",
                status: "${data.filterBy}" ) {    bids {
                  case_bid_id
                  id
                  awarded_date
                  service_provider_id
                  bid_name
                  bid_status
                  counter_offer
                  created_date
                  date_accepted
                  contract_start_date
                  contract_end_date
                  service_line
                  bid_sent_id
                  bid_location_name
                  site_street
                  site_city
                  site_country
                  site_postal_code
                  site_lat
                  site_lon
                  customer_name
                  service_provider_name
                  contract_value
                  service_details
                  bid_response_deadline
                }
                pagination {
                    found_rows
                }
              }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.bidsToServiceProvider) {
            return Promise.resolve(res.data.data.bidsToServiceProvider)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getBidsLocation: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `
            query bidsToServiceProdiver{
              bidsToServiceProvider(
              sfid:"${data.sfid}",
              limit: ${data.limit},
              offset: ${data.offset},
              sort: "${data.sort}",
              sortorder: "${data.order}",
              status: "${data.filterBy}" ) {
                bids {
                  case_bid_id
                  id
                  bid_location_name
                  site_lat
                  site_lon
                }
                pagination {
                    found_rows
                }
              }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.bidsToServiceProvider) {
            return Promise.resolve(res.data.data.bidsToServiceProvider)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    acceptBid: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .post(
          "/api",
          {
            query: `
          mutation updateBid($bid_data: BidInput!) {
          updateBid(bid_data: $bid_data) {
            id
          }
        }`,
            variables: {
              bid_data: {
                bid_id: data.sfid,
                bid_status: "Accepted",
              },
            },
          },
          {
            signal,
          }
        )
        .then((res) => {
          return Promise.resolve()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    rejectBid: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .post(
          "/api",
          {
            query: `
          mutation updateBid($bid_data: BidInput!) {
          updateBid(bid_data: $bid_data) {
            id
          }
        }`,
            variables: {
              bid_data: {
                bid_id: data.sfid,
                bid_status: "Declined",
              },
            },
          },
          {
            signal,
          }
        )
        .then((res) => {
          return Promise.resolve()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    makeCounterBid: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .post(
          "/api",
          {
            query: `
          mutation updateBid($bid_data: BidInput!) {
          updateBid(bid_data: $bid_data) {
            id
          }
        }`,
            variables: {
              bid_data: {
                bid_id: data.sfid,
                bid_status: "Counter",
                counter: data.counters,
              },
            },
          },
          {
            signal,
          }
        )
        .then((res) => {
          return Promise.resolve()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getBidDetails: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query bidDetails{
              bidDetails(sfid:"${data.sfid}") {
                case_bid_id
                id
                awarded_date
                attentive_link
                service_provider_id
                service_provider_name
                customer_id
                customer_name
                bid_name
                bid_status
                counter_offer
                created_date
                bid_response_deadline
                date_accepted
                contract_start_date
                contract_end_date
                service_line
                bid_sent_id
                site_id
                bid_location_name
                site_street
                site_city
                site_country
                site_postal_code
                site_lat
                site_lon
                contract_value
                service_details
                service_line
                bid_response_deadline
                exhibit_c_verbiage__c
                exhibits {
                    createddate
                    filetype
                    contentsize
                    sfid
                    contentdocumentid
                    title
                }
                details {
                    id
                    sfid
                    details_name
                    createddate
                    bid_sfid
                    counter_offer
                    counter_offer_price
                    site_id
                    site_bid_details
                    increment
                    service_type
                    bid_details_title
                    notes
                    case_snow_offer
                    sort_order
                    price
                    frequency
                    color_selection
                    service_start_date	
                    service_end_date
                    service_frequency
                    expected_service_days
                    service_key
                }
              }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.bidDetails) {
            return Promise.resolve(res.data.data.bidDetails)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getSites: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `
            query sitesByServiceProvider {
                sitesByServiceProvider(
                providerId:"${data.sfid}",
                limit: ${data.limit},
                offset: ${data.offset},
                sort: "${data.sort}",
                sortorder: "${data.order}",
                searchText: "${data.search || ""}",
                ${!!data?.filterBy && typeof data?.filterBy === "string" ? `,status: "${data?.filterBy}"` : ""}) {
                    sites {
                        id
                        sfid
                        service_provider_id
                        service_provider
                        name
                        account_name
                        primary_field_mobile__c
                        primary_field_email__c
                        billinglatitude
                        billinglongitude
                        billinggeocodeaccuracy
                        billingstreet
                        billingcity
                        billingstate
                        billingpostalcode
                        billingcountry
                        shippinglatitude
                        shippinglongitude
                        shippinggeocodeaccuracy
                        shippingstreet
                        shippingcity
                        shippingstate
                        shippingpostalcode
                        shippingcountry
                        service_line
                        location_service_notes__c
                        special_requirements__c
                        status
                        last_serviced_date
                        contract_end_date__c
                        contract_start_date__c
                        work_orders
                    }
                    total_worklogs
                    pagination {
                        found_rows
                        offset
                    }
                }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.sitesByServiceProvider) {
            return Promise.resolve(res.data.data.sitesByServiceProvider)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getSitesLocations: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `
            query sitesByServiceProvider {
              sitesByServiceProvider(
              providerId:"${data.sfid}",
              limit: 1000,
              offset: 0,
              sort: "name",
              sortorder: "ASC"
              status: "Active") {
                sites {
                  id
                  sfid
                  account_name
                  shippinglatitude
                  shippinglongitude
                }
                total_worklogs
                pagination {
                  found_rows
                } 
              }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.sitesByServiceProvider) {
            return Promise.resolve(res.data.data.sitesByServiceProvider)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getUsers: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query usersToServiceProvider {
              usersToServiceProvider(
                providerId: "${data.sfid}",
                limit: ${data.limit},
                offset: ${data.offset},
                sort: "${data.sort}",
                sortorder: "${data.order}") {
                  user_data {
                      id
                      profile_id
                      service_provider_id
                      service_provider_name
                      phone_number
                      email
                      firstname
                      lastname
                      createddate
                      updateddate
                      roles {
                          name
                      }
                  }
                  pagination {
                      found_rows
                  } 
              }
          }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.usersToServiceProvider) {
            return Promise.resolve(res.data.data.usersToServiceProvider)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getWorkOrders: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query worklogs{
                worklogs(
                  providerId: "${data.providerId}", 
                  limit: ${data.limit},
                  offset: ${data.offset},
                  sort: "${data.sort}",
                  order: "${data.order}",
                  status: "${data.status || "All"}",
                  searchText: "${data.search || ""}",
                  ${!!data.siteId ? `,siteId: "${data.siteId}"` : ""}) {
                    worklogs {
                      sfid
                      name
                      approval_status
                      billing_status
                      data_source
                      external_id
                      service_subscription_id
                      service_subscription
                      service_date
                      service_summary
                      time_taken_minutes
                      location_id
                      location_latitude
                      location_longitude
                      location_name
                      submitted
                      service_provider_account__c
                      service_type
                      status
                      cancelled
                      createddate
                    }
                    pagination {
                        found_rows
                    }
                }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.worklogs) {
            return Promise.resolve(res.data.data.worklogs)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getPartnerBySfid: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query servicePartnerById{
                servicePartnerById(sfid: "${data.sfid}") {    
                    sfid
                    name
                    first_name
                    last_name
                    mobilephone
  
                    primary_field_poc_email
                    primary_field_poc_cell
                    primary_field_poc
                    website
  
                    billinglatitude
                    billinglongitude
                    billinggeocodeaccuracy
                    billingstreet
                    billingcity
                    billingstate
                    billingpostalcode
                    billingcountry
  
                    trucks_in_fleet
                    number_of_trucks_dedicated_to_case_sites
                    sander_de_icer_s_in_fleet
                    loaders_in_fleet
                    skidsteers_in_fleet
                    pushers_in_fleet

                    insurance_compliance
                    insurance_status
                    insurance_end_date
                    insurance_pending_reason
                    insurance_start_date
                    compliance_date
                    general_liability_policy_end_date
                    gl_carrier
                    gl_agent_name
                    gl_agent_email
                    gl_agent_phone
                    workers_comp_expired
                    workmans_comp_end_date
                    workmans_comp_start_date
                    auto_policy_end_date
                    auto_policy_start_date
                    auto_account_num
                    auto_account_number
                    tax_id_1099
                    w_9_on_file
                    compliance_note_reporting
                    customer_notes
                    location_service_notes
                    service_non_compliance_note
                    insurance_noncompliance
                    compliance_note_to_sp
                    coi_uploaded_file
                    gl_uploaded_file
                    w9_uploaded_file
                    diversity_opt_out
                    diversity

                    contacts {
                        id
                        sfid
                        title
                        email
                        name
                        firstname
                        lastname
                        phone
                        mobilephone
                    }        
                }
            }`,
          },
        })
        .then((res) => {
          if (!!res?.data?.data?.servicePartnerById) {
            return Promise.resolve(res.data.data.servicePartnerById)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getPartnerByName: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query servicePartners {
              servicePartners(limit: 1, offset:0, name: "${data.parnterName}") {
                name
                mobile_access_code__c
                sfid
                billinglatitude
                billinglongitude
                billingstreet
                billingcity
                billingstate
                billingcountry
                billingpostalcode
              }
            }`,
          },
        })
        .then((res) => {
          if (!!res?.data?.data?.servicePartners.length) {
            return Promise.resolve(res.data.data.servicePartners[0])
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getLocation: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query location {
              location(locationId: "${data.siteId}") {
                id
                sfid
                service_provider_id
                service_provider
                name
                account_name
                primary_field_mobile__c
                primary_field_email__c
                billinglatitude
                billinglongitude
                billinggeocodeaccuracy
                billingstreet
                billingcity
                billingstate
                billingpostalcode
                billingcountry
                shippinglatitude
                shippinglongitude
                shippinggeocodeaccuracy
                shippingstreet
                shippingcity
                shippingstate
                shippingpostalcode
                shippingcountry
                location_service_notes__c
                special_requirements__c
                special_requirements_land__c
                status
                last_serviced_date
                contract_end_date__c
                contract_start_date__c
                attachments {
                    title
                    sfid
                    createddate
                    filetype
                }
              }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.location) {
            return Promise.resolve(res.data.data.location)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getLocationToServiceProvider: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query siteToServiceProvider {
                siteToServiceProvider(
                siteId: "${data.siteId}",
                providerId: "${data.sfid}") {
                    id
                    sfid
                    service_provider_id
                    service_provider
                    name
                    account_name
                    primary_field_mobile__c
                    primary_field_email__c
                    primary_field_poc__c
                    billinglatitude
                    billinglongitude
                    billinggeocodeaccuracy
                    billingstreet
                    billingcity
                    billingstate
                    billingpostalcode
                    billingcountry
                    shippinglatitude
                    shippinglongitude
                    shippinggeocodeaccuracy
                    shippingstreet
                    shippingcity
                    shippingstate
                    shippingpostalcode
                    shippingcountry
                    location_service_notes__c
                    special_requirements__c
                    service_line
                    last_serviced_date
                    status
                    work_orders
                    contract_end_date__c
                    contract_status__c
                    contract_start_date__c
                    service_requests {
                        cases {
                            serviceManagementId
                            source
                            externalId
                            siteId
                            customerId
                            createdDate
                            lastUpdatedDate
                            type
                            caseId
                        }
                        count
                    }
                    site_contacts {
                        title
                        name
                        firstname
                        lastname
                        phone
                        mobilephone
                        email
                    }
                    provider_contacts {
                        title
                        name
                        firstname
                        lastname
                        phone
                        mobilephone
                        email
                    }
                    attachments {
                        sfid
                        createddate
                        filetype
                        title
                    }
                }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.siteToServiceProvider) {
            return Promise.resolve(res.data.data.siteToServiceProvider)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getWorkLogDetails: (data: any, signal?: AbortSignal, isExternal = false) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query worklog {
                worklog(${isExternal ? "externalId" : "worklogId"}: "${data.sfid}") {
                    sfid
                    name
                    approval_status
                    billing_status
                    data_source
                    external_id
                    service_subscription_id
                    service_subscription
                    service_date
                    service_summary
                    time_taken_minutes
                    location_id
                    location_latitude
                    location_longitude
                    location_name
                    submitted
                    cancelled
                    service_provider_account__c
                    status
                    createddate
                    comments
                    attachments {
                        title
                        sfid
                        createddate
                        filetype
                    }
                    services {
                      service_id
                      external_id
                      sfid
                      service_key
                      service_name
                      service_type
                      status
                      approval_status
                      algorithm_status
                    }
                }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.worklog) {
            return Promise.resolve(res.data.data.worklog)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    updateServicePartnerEquipment: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .post(
          "/api",
          {
            query: `
          mutation updateServicePartner($data: ServiceProviderInput!) {
            updateServicePartner(id: "${data.sfid}", service_partner: $data) {
              sfid
              trucks_in_fleet
              number_of_trucks_dedicated_to_case_sites
              sander_de_icer_s_in_fleet
              loaders_in_fleet
              skidsteers_in_fleet
              pushers_in_fleet
              gl_carrier
              gl_agent_name
              gl_agent_email
              gl_agent_phone
              diversity_opt_out
              diversity
            }
          }`,
            variables: {
              data: data.values,
            },
          },
          {
            signal,
          }
        )
        .then((res) => {
          return Promise.resolve()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getDashboardData: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `query servicePartnerDashboard {
                servicePartnerDashboard(sfid:"${data.sfid}") {
                    open_bids {
                        bids {
                            case_bid_id
                            bid_location_name
                            site_lat
                            site_lon
                        }
                        pagination {
                            found_rows
                        }
                    } 
                    pending_bids {
                        bids {
                            case_bid_id
                            bid_location_name
                            site_lat
                            site_lon
                        }
                        pagination {
                            found_rows
                        }
                    } 
                    awarded_bids {
                        bids {
                            case_bid_id
                            bid_location_name
                            site_lat
                            site_lon
                        }
                        pagination {
                            found_rows
                        }
                    } 
                    active_sites {
                        sites {
                            site_id
                            site_name
                            site_lat
                            site_lon
                        }
                        pagination {
                            found_rows
                        }
                    } 
                }
            }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.servicePartnerDashboard) {
            return Promise.resolve(res.data.data.servicePartnerDashboard)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    searchPartners: (data: any, signal?: AbortSignal) => {
      return axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `
              query servicePartners {
                servicePartners(
                  limit:${data.limit ?? 10},
                  offset:${data.offset ?? 0},
                  ${data.lat ? `latitude: ${data.lat},` : ""}
                  ${data.long ? `longitude: ${data.long},` : ""}
                  ${data.mac ? `mac: ${data.mac},` : ""}
                  name: \"${data.name ?? ""}\",
                  distance: ${data.distance ?? 0}) {
                    sfid
                    id
                    name
                    mobile_access_code__c
                    createddate
                    location_service_notes__c
                    special_requirements__c
                    isdeleted
                    billinglatitude
                    billinglongitude
                    billinggeocodeaccuracy
                    billingstreet
                    billingcity
                    billingstate
                    billingpostalcode
                    billingcountry
                    shippinglatitude
                    shippinglongitude
                    shippinggeocodeaccuracy
                    shippingstreet
                    shippingcity
                    shippingstate
                    shippingpostalcode
                    shippingcountry
                    distance
                  }
              }`,
          },
        })
        .then((res) => {
          if (res?.data?.data?.servicePartners) {
            return Promise.resolve(res.data.data.servicePartners)
          }
          return Promise.reject()
        })
        .catch(() => {
          return Promise.reject()
        })
    },
    getUsersByTitle: (title: string, limit: number, offset: number, signal?: AbortSignal): Promise<{ users: User[]; pagination: Pagination }> =>
      axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `
            query getUsers {
              getUsers(title: "${title}", limit: ${limit}, offset: ${offset}) {
                users{
                    id
                    sfid
                    mobilephone
                    phone
                    email
                    firstname
                    lastname
                    title
                }
                pagination {
                    found_rows
                    limit
                    offset
                }
              }
            }`,
          },
        })
        .then((res) => (res?.data?.data?.getUsers ? Promise.resolve(res.data.data.getUsers) : Promise.reject()))
        .catch(() => Promise.reject()),
    getValidations: (
      accountManagerId: string,
      stormStart: string,
      stormEnd: string,
      status: string,
      order: string,
      pageSize: number = 10,
      siteId?: string,
      nextPageToken?: string | null,
      signal?: AbortSignal
    ): Promise<{
      items: Validation[]
      nextPageToken: any
      totalCount: number
    }> =>
      axiosInstance
        .get("/api", {
          signal,
          params: {
            query: `
          query worklogValidation {
            worklogValidation(accountManagerId: "${accountManagerId}", stormStart: "${stormStart}",
                    stormEnd: "${stormEnd}",${status.length ? ' status: "' + status + '",' : ""}${
              order ? ' order: "' + order + '",' : ""
            } pageSize: ${pageSize}${nextPageToken ? ', nextPageToken: "' + nextPageToken + '"' : ""}${siteId ? ', siteId: "' + siteId + '"' : ""}) {
                    items {
                        schema
                        schemaVersion
                        validationRuleSet
                        name
                        validations {
                            period,
                            periodStart,
                            periodEnd,
                            services {
                                category
                                min
                                max
                                count
                            }
                            worklogs {
                                services {
                                    service
                                    status
                                    reason
                                    count
                                }
                                datetime
                                workLogId
                                workOrderId
                                duplicate
                                serviceSummary
                                approvalStatus
                                algorithmStatus
                                serviceProvider
                                serviceProviderId
                                servicePartnerNotes
                            }
                        }
                        algorithmStatus
                        description
                        storm {
                            id
                            start
                            end
                            locationId
                            city
                            state
                            zipCode
                            precip_type
                            total
                            total_accumulation
                            total_trace
                            ice
                            ice_trace
                            note
                            title
                            narrative
                            footnote
                            stormStart
                            stormEnd
                        }
                        createdAt
                        stormId
                        siteId
                        siteName
                        serviceProvider
                        serviceProviderId
                        accountManagerId
                        status
                        _metaData {
                            id
                            _rid
                            _self
                            _etag
                            _attachments
                            _ts
                        }
                    },
                    nextPageToken
                    totalCount
                }
            }`,
          },
        })
        .then((res) => (res?.data?.data?.worklogValidation ? Promise.resolve(res.data.data.worklogValidation) : Promise.reject()))
        .catch(() => Promise.reject()),
    updateWorkOrderValidation: (validationData: ValidationData, signal?: AbortSignal): Promise<boolean> => {
      return axiosInstance
        .post("/api", {
          signal,
          query: `
            mutation updateValidation($validationData: ValidationInput!) {
              updateValidation(validationData: $validationData) {
                  success
              }
          }`,
          variables: {
            validationData,
          },
        })
        .then((res) => {
          if (res?.data?.data?.updateValidation) {
            return Promise.resolve(res.data.data.updateValidation?.success)
          }
          return Promise.reject(false)
        })
        .catch(() => {
          return Promise.reject(false)
        })
    },
    updateWorkOrderNotes: (worklog: ValidationWorklogData, signal?: AbortSignal): Promise<any> => {
      return axiosInstance
        .post("api", {
          signal,
          query: `
        mutation updateWorkOrder($worklog_data: WorklogInput!) {
          updateWorkOrder(worklog: $worklog_data) {
            message
          }
        }`,
          variables: {
            worklog_data: {
              work_log_id: worklog.work_log_id,
              service_partner_notes: worklog.service_partner_notes,
              work_log_event: {
                type: worklog.work_log_event.type,
              },
            },
          },
        })
        .then((res) => (res?.data?.data?.updateWorkOrder ? Promise.resolve(res.data.data.updateWorkOrder) : Promise.reject()))
        .catch(() => Promise.reject())
    },
    getOrgRolesByOrgId: (
      orgId: string,
      signal?: AbortSignal
    ): Promise<
      {
        role_id: string
        name: string
      }[]
    > =>
      axiosInstance
        .post("api", {
          signal,
          query: `
        query servicePartnerRoles {
          servicePartnerRoles(sfid: "${orgId}") {
              role_id
              name
          }
      }`,
        })
        .then((res) => (res?.data?.data?.servicePartnerRoles ? Promise.resolve(res.data.data.servicePartnerRoles) : Promise.reject()))
        .catch(() => Promise.reject()),
    getLandValidations: (
      dateStart: string | undefined,
      pageSize: number,
      siteId: string,
      dateEnd: string | undefined,
      orderBy: string,
      order: string,
      signal?: AbortSignal
    ): Promise<LandValidation[] | undefined> =>
      axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
              query worklogLandValidations {
                worklogLandValidations(
                  ${dateStart ? `dateStart: "${dateStart}",` : ""}
                  pageSize: ${pageSize},
                  siteId: "${siteId}",
                  ${dateEnd ? `dateEnd: "${dateEnd}",` : ""}
                  order: "${orderBy}:${order}"
                  ) {
                    items {
                        sfid
                        siteId
                        siteName
                        procurementBidId
                        servicePartnerId
                        scheduleName
                        serviceStart
                        serviceEnd
                        serviceType
                        serviceFrequency
                        serviceLine
                        serviceKey
                        servicePartnerName
                        bidAwardedDate
                        servicePeriods {
                            startDate
                            endDate
                            endDatePlusOne
                            reminderDate
                            serviceKey
                            serviceType
                            expectedServiceCount
                            actualServiceCount
                            missingServiceCount
                            overServiceCount
                            expected
                            servicePartnerMismatch
                            reviewComplete
                            quarterNumber
                            monthNumber
                            weekNumber
                            worklogs {
                              worklogId
                              workLogStart
                              workLogEnd
                              backDatedTo
                              algorithmStatus
                              reasons
                              work_order_name
                              service_summary
                              distance_from_site
                              sfid
                              time_taken_minutes
                              service_provider_account__c
                              service_provider
                              service_partner_notes
                              woms_wo
                              approval_status
                              servicePartnerMismatch
                              duplicateWorklog
                              service {
                                sfid
                                name
                                service_key
                                service_type
                                value
                                approval_status
                                algorithm_status
                                approval_notes
                              }
                            }
                        }
                        _metaData {
                            createdAt
                            schema
                            type
                            id
                            _rid
                            _self
                            _etag
                            _attachments
                            _ts
                        }
                    }
                    nextPageToken
                    totalCount
                }
            }
            `,
          },
        })
        .then((res) =>
          res?.data?.data?.worklogLandValidations?.items ? Promise.resolve(res.data.data.worklogLandValidations.items) : Promise.reject()
        )
        .catch(() => Promise.reject()),
    getLandWorklog: (worklogId: string | null, signal?: AbortSignal): Promise<LandWorklogDetails | undefined> =>
      axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
            query worklogLandValidationResult {
              worklogLandValidationResult(
                worklogId: "${worklogId}"
              ) {
                workLogId
                createdAt
                updatedAt
                status
                serviceRequestIds
                serviceRequests
                siteDetails {
                  siteId
                  latitude
                  longitude
                  timezoneOffset
                  timezoneId
                }
                serviceData {
                  startedDate
                  serviceSubscriptionId
                  serviceLine
                }
                services {
                  name
                  serviceKey
                  serviceDefinition
                  serviceLine
                  serviceId
                  status
                  scheduleId
                  periodIndex
                  valid
                  reasons
                }
                notes
                attachments {
                  title
                  sfid
                  s3id
                  createddate
                  contenttype
                  objecttype
                  filetype
                  etag
                  location
                  key
                  bucket
                }
                userDetails {
                  userId
                  fullName
                  phone
                  servicePartnerId
                }
                userDeviceLocation {
                  timestamp
                  mocked
                  coords {
                    accuracy
                    altitude
                    altitudeAccuracy
                    heading
                    latitude
                    longitude
                    speed
                  }
                }
                mobileAppDetails {
                  mobileAppVersion
                  mobileAppBuildNumber
                  dataSource
                }
                workOrderId
                backdatedTo
                _metaData {
                  createdAt
                  schema
                  type
                  id
                  _rid
                  _self
                  _etag
                  _attachments
                  _ts
                }
                algorithmStatus
              }
            }
`,
          },
        })
        .then((res) => (res?.data?.data?.worklogLandValidationResult ? Promise.resolve(res.data.data.worklogLandValidationResult) : Promise.reject()))
        .catch(() => Promise.reject()),
    getSitesByUserSfid: (sfid: string, signal?: AbortSignal): Promise<{ sfid: string; name: string }[] | undefined> =>
      axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
            query sitesByUser {
              sitesByUser (sfid: "${sfid}") {
                sfid
                name
              }
          }`,
          },
        })
        .then((res) => (res?.data?.data?.sitesByUser ? Promise.resolve(res.data.data.sitesByUser) : Promise.reject()))
        .catch(() => Promise.reject()),
    updateLandValidation: (validationData: ValidationData, signal?: AbortSignal): Promise<boolean> => {
      return axiosInstance
        .post("/api", {
          signal,
          query: `
            mutation updateWorklogLandValidation($validationData: ValidationInput!) {
              updateWorklogLandValidation(validationData: $validationData) {
                  message
                  success
              }
          }`,
          variables: {
            validationData,
          },
        })
        .then((res) => {
          if (res?.data?.data?.updateWorklogLandValidation) {
            return Promise.resolve(res.data.data.updateWorklogLandValidation?.success)
          }
          return Promise.reject(false)
        })
        .catch(() => {
          return Promise.reject(false)
        })
    },
    getLandWorklogs: (
      dateStart: string,
      pageSize: number,
      siteId: string,
      dateEnd: string,
      orderBy: string,
      order: string,
      signal?: AbortSignal
    ): Promise<LandWorklogDetails[] | undefined> =>
      axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
            query worklogLandValidationResults {
              worklogLandValidationResults(
                dateStart: "${dateStart}",
                pageSize: ${pageSize},
                siteId: "${siteId}",
                dateEnd: "${dateEnd}",
                order: "${orderBy}:${order}"
              ) {
                workLogId
                createdAt
                updatedAt
                backdatedTo
                status
                serviceRequestIds
                serviceRequests
                siteDetails {
                  siteId
                  latitude
                  longitude
                  timezoneOffset
                  timezoneId
                }
                serviceData {
                  startedDate
                  serviceSubscriptionId
                  serviceLine
                }
                services {
                  name
                  serviceKey
                  serviceDefinition
                  serviceLine
                  serviceId
                  status
                  scheduleId
                  periodIndex
                  valid
                  reasons
                }
                notes
                attachments {
                  title
                  sfid
                  s3id
                  createddate
                  contenttype
                  objecttype
                  filetype
                  etag
                  location
                  key
                  bucket
                }
                userDetails {
                  userId
                  fullName
                  phone
                  servicePartnerId
                }
                userDeviceLocation {
                  timestamp
                  mocked
                  coords {
                    accuracy
                    altitude
                    altitudeAccuracy
                    heading
                    latitude
                    longitude
                    speed
                  }
                }
                mobileAppDetails {
                  mobileAppVersion
                  mobileAppBuildNumber
                  dataSource
                }
                workOrderId
                backdatedTo
                _metaData {
                  createdAt
                  schema
                  type
                  id
                  _rid
                  _self
                  _etag
                  _attachments
                  _ts
                }
                algorithmStatus
              }
            }
`,
          },
        })
        .then((res) =>
          res?.data?.data?.worklogLandValidationResults ? Promise.resolve(res.data.data.worklogLandValidationResults) : Promise.reject()
        )
        .catch(() => Promise.reject()),
    getLandValidationListing: (
      reviewer?: string,
      servicePartner?: string,
      customer?: string,
      siteId?: string,
      requiresReview: boolean = false,
      sortBy?: SortField,
      sortOrder: "desc" | "asc" | "DESC" | "ASC" = "asc",
      pageSize: number = 10,
      nextPageToken?: string,
      signal?: AbortSignal
    ): Promise<LandValidationListingResponse | undefined> =>
      axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
              query getLandValidationListing {
                getLandValidationListing(
                  ${reviewer ? `reviewer: "${reviewer}"` : ""}
                  ${servicePartner ? `servicePartner: "${servicePartner}"` : ""}
                  ${customer ? `customer: "${customer}"` : ""}
                  ${siteId ? `siteId: "${siteId}"` : ""}
                  ${`pageSize: ${pageSize}`}
                  requiresReview: ${requiresReview}
                  ${sortBy ? `sort: "${sortBy}"` : ""}
                  ${sortBy ? `sortorder: "${sortOrder}"` : ""}
                  ${nextPageToken ? `nextPageToken: "${nextPageToken}"` : ""}
                ) {
                  items {
                      sfid
                      siteId
                      reviewerIds
                      siteName
                      procurementBidId
                      servicePartnerId
                      servicePartnerName
                      customerId
                      customerName
                      scheduleName
                      serviceStart
                      serviceEnd
                      serviceType
                      requiresReview
                      serviceFrequency
                      serviceLine
                      serviceKey
                      serviceDays {
                        day
                        completed
                      }
                      servicePeriods {
                        startDate
                        endDate
                        endDatePlusOne
                        reminderDate
                        serviceKey
                        serviceType
                        expectedServiceCount
                        actualServiceCount
                        missingServiceCount
                        overServiceCount
                        servicePartnerMismatch
                        expected
                        reviewComplete
                        quarterNumber
                        monthNumber
                        weekNumber
                        worklogs {
                          sfid
                          worklogId
                          workLogStart
                          workLogEnd
                          backDatedTo
                          algorithmStatus
                          workOrderId
                          work_order_name
                          servicePartnerMismatch
                          duplicateWorklog
                          approval_status
                          reasons
                          service {
                            sfid
                            name
                            service_key
                            service_type
                            value
                            approval_status
                            algorithm_status
                            approval_notes
                          }
                        }
                      }
                    }
                    nextPageToken
                    totalCount
                  }
                }`,
          },
        })
        .then((res) =>
          res?.data?.data?.getLandValidationListing?.items
            ? Promise.resolve({
                items: res.data.data.getLandValidationListing.items,
                nextPageToken: res.data.data.getLandValidationListing.nextPageToken,
                totalCount: res.data.data.getLandValidationListing.totalCount,
              })
            : Promise.reject()
        )
        .catch(() => Promise.reject()),
    getSnowWorklogs: (
      data: {
        amId: string
        locationId?: string
        approvalStatus?: string
        startDate: string
        endDate: string
        limit?: number
        sort?: string
        order?: "asc" | "desc" | "ASC" | "DESC"
        nextPageToken?: string
      },
      signal?: AbortSignal
    ): Promise<SnowWorklogsResponse | undefined> => {
      return axiosInstance
        .get("api", {
          signal,
          params: {
            query: `query getSnowWorkLogsForValidation {
                        getSnowWorkLogsForValidation(
                          amId: "${data.amId}"
                          startDate: "${data.startDate}"
                          endDate: "${data.endDate}"
                          limit: ${data.limit ?? 10}
                          sort: "${data.sort}"
                          order: "${data.order ? data.order.toLowerCase() : "desc"}"
                          ${data.nextPageToken ? `nextPageToken: "${data.nextPageToken}"` : ""}
                          ${data.locationId ? `locationId: "${data.locationId}"` : ""}
                          ${data.approvalStatus ? `approvalStatus: "${data.approvalStatus}"` : ""}
                        ) {
                          worklogs {
                            workLogId
                            createdAt
                            updatedAt
                            status
                            notes
                            isReviewed
                            workOrderId
                            WOMSWorkLogID
                            serviceSummary
                            siteDetails {
                              siteId
                            }
                            services {
                              serviceId
                              serviceManagementId
                              value
                            }
                            siteName
                            serviceData {
                              startedDate
                              serviceLine
                              serviceSubscriptionId
                            }
                            workOrderName
                            workOrderStatus
                          }
                          nextPageToken
                          totalRows
                      }
                    }`,
          },
        })
        .then((res) =>
          res?.data?.data?.getSnowWorkLogsForValidation ? Promise.resolve(res.data.data.getSnowWorkLogsForValidation) : Promise.reject()
        )
        .catch(() => Promise.reject())
    },
    getWorklogsSet: (
      providerId: string,
      dateStart: string,
      dateEnd: string,
      approval_status?: string,
      locationId?: string,
      serviceLine?: string,
      signal?: AbortSignal
    ) =>
      axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
                query worklogSet {
                  worklogSet(
                    providerId: "${providerId}",
                    startDate: "${dateStart}",
                    endDate: "${dateEnd}",
                    ${approval_status ? `approval_status: "${approval_status}"` : ""}
                    ${locationId ? `locationId: "${locationId}"` : ""}
                  ) {
                    worklogs {
                      name
                      location_name
                      service_summary
                      service_date
                      time_taken_minutes
                      approval_status
                    }
                    counts {
                      count
                      service_key
                    }
                    pagination {
                      limit
                      offset
                      found_rows
                    }
                  }
                }`,
          },
        })
        .then((res) =>
          res?.data?.data?.worklogSet?.worklogs
            ? Promise.resolve({
                worklogs: res.data.data.worklogSet.worklogs,
                count: res.data.data.worklogSet.count,
                pagination: res.data.data.worklogSet.pagination,
              })
            : Promise.reject()
        )
        .catch(() => Promise.reject()),
    updateWorkOrderService: (serviceData: ServiceDataUpdate, signal?: AbortSignal): Promise<boolean> =>
      axiosInstance
        .post("/api", {
          signal,
          query: `
              mutation updateWorkOrderService($serviceData: ServicesInput!) {
                updateWorkOrderService(serviceData: $serviceData) {
                  message
                  success
                }
              }`,
          variables: {
            serviceData,
          },
        })
        .then((res) => {
          if (res?.data?.data?.updateWorkOrderService) {
            return Promise.resolve(res.data.data.updateWorkOrderService?.success)
          }
          return Promise.reject(false)
        })
        .catch(() => {
          return Promise.reject(false)
        }),
    updateServicePeriodCompletion: (serviceData: ServicePeriodCompletionData, signal?: AbortSignal): Promise<boolean> => {
      return axiosInstance
        .post("/api", {
          signal,
          query: `
              mutation completeServicePeriod($serviceData: servicePeriodInput!) {
                completeServicePeriod(serviceData: $serviceData) {
                  message
                  success
                }
              }`,
          variables: {
            serviceData,
          },
        })
        .then((res) => {
          if (res?.data?.data?.completeServicePeriod) {
            return Promise.resolve(res.data.data.completeServicePeriod?.success)
          }
          return Promise.reject(false)
        })
        .catch(() => {
          return Promise.reject(false)
        })
    },
    updateWorklogReviewStatus: (work_log_data: { work_log_id: string; isReviewed: boolean }, signal?: AbortSignal): Promise<boolean> => {
      return axiosInstance
        .post("/api", {
          signal,
          query: `
              mutation updateWorklog($work_log_data: WorklogInput!) {
                updateWorklog(worklog: $work_log_data) {
                  message
                }
              }`,
          variables: {
            work_log_data,
          },
        })
        .then((res) => {
          if (res?.data?.data?.updateWorklog) {
            return Promise.resolve(res.data.data.updateWorklog?.message?.includes("success"))
          }
          return Promise.reject(false)
        })
        .catch(() => {
          return Promise.reject(false)
        })
    },
    getAccounts: (type: string, search: string = "", limit: number = 2000, signal?: AbortSignal): Promise<AccountResponse> =>
      axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
              query accounts {
                accounts(type: "${type}", limit: ${limit}, name: "${search}") {
                    accounts {
                        sfid
                        name
                    }
                    pagination {
                        limit
                        offset
                        found_rows
                    }
                }
            }`,
          },
        })
        .then((res) => (res?.data?.data?.accounts?.accounts ? Promise.resolve(res?.data?.data?.accounts) : Promise.reject()))
        .catch(() => Promise.reject()),
    getOrgUsers: (
      attr: {
        orgId: string
        limit: number
        sort?: string
        searchText?: string
        sortOrder?: "ASC" | "DESC"
        roles?: string[]
        offset?: number
      },
      signal?: AbortSignal
    ): Promise<OrgUsersResponse> => {
      const {
        orgId,
        limit,
        sort,
        searchText,
        sortOrder,
        offset = 0,
        roles = ["Service Partner Owner", "Service Partner Admin", "Service Technician", "Case Owner"],
      } = attr

      return axiosInstance
        .get("api", {
          signal,
          params: {
            query: `
                  query getOrgUsers {
                    getOrgUsers(orgId: "${orgId}" roles: [${roles.map((r) => `"${r}"`)}] limit: ${limit} offset: ${offset} ${
              sort ? `sort: "${sort}"` : ""
            } ${sortOrder ? `sortorder: "${sortOrder}"` : ""} ${searchText ? `searchText: "${searchText}"` : ""}) {
                        pagination {
                                found_rows
                                offset
                                limit
                            }
                        rows {
                            id
                            email
                            phone_number
                            organization_roles {
                                id
                                role_id
                                organization_id
                                organization_type
                                name
                                user_organization_roles {
                                    user_id
                                    org_role_id
                                },
                                organization {
                                    id
                                    name
                                    sf_account_id
                                    created_by
                                    updated_by
                                    created_at
                                    updated_at
                                }
                            }
                            profiles {
                                id
                                user_id
                                name_first
                                name_last
                                partner_id
                                title
                                created_at
                                updated_at
                            }
                            authorizations {
                                id
                                user_id
                                created_at
                                updated_at
                            }
                        }
                    }
                }`,
          },
        })
        .then((res) => (res?.data?.data?.getOrgUsers ? Promise.resolve(res?.data?.data?.getOrgUsers as OrgUsersResponse) : Promise.reject()))
        .catch(() => Promise.reject())
    },
    uploadDocument: (form_data: FormData) =>
      axiosInstance
        .post<S3Document>("rest/v1/documents/compliance", form_data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          Promise.resolve()
        })
        .catch(Promise.reject),
  }
}
