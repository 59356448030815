import {
  makeStyles,
  createStyles,
  Typography,
  Container,
  Grid,
  Divider,
  Button,
  Modal,
  Box,
} from "@material-ui/core"
import { ReactComponent as UserIcon } from "./../../../../assets/user-b.svg"
import { ReactComponent as MessageIcon } from "./../../../../assets/message-b.svg"
import React, { useState } from "react"
import Carousel from "react-material-ui-carousel"
import { ReactComponent as CloseIcon } from "./../../../../assets/close-w.svg"
import useI18n from "../../../../hooks/useI18n"

export const SiteDetailsCard = (props: any) => {
  const classes = useStyles()
  const { i18n } = useI18n();
  const [openImg, setOpenImg] = useState(false)

  const openImage = () => {
    setOpenImg(true)
  }

  return (
    <>
      <Modal
        open={openImg}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => setOpenImg(false)}
      >
        <Box className={classes.styleImg}>
          <div className={classes.iframeBlockImg}>
            <div className="p-8">
              <Carousel
                animation={"slide"}
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                navButtonsProps={{
                  style: {},
                }}
              >
                {!!props?.images.length &&
                  props?.images.map((img: any, i: any) => (
                    <div key={i} className="flex flex-col items-center">
                      <img
                        className={classes.carouselImg}
                        alt="photo"
                        src={img}
                      />
                    </div>
                  ))}
              </Carousel>
            </div>

            <Button
              variant="text"
              onClick={() => setOpenImg(false)}
              className={classes.imgButton}
            >
              <CloseIcon />
            </Button>
          </div>
        </Box>
      </Modal>
      <div>
        <div>
          <Typography className={classes.titleFont}>{i18n.t("sites.details.caseContacts")}</Typography>
        </div>
        <div className={classes.grid}>
          <div>
            {props?.site?.site_contacts.map((contact: any, i: any) => (
              <React.Fragment key={i}>
                {(contact.title == "Account Associate" ||
                  contact.title == "Field Supervisor" ||
                  contact.title == "Regional Field Manager" ||
                  contact.title == "Senior Account Manager") && (
                  <Container className={classes.container}>
                    <Grid container>
                      <Grid item xs={3} className="flex flex-row items-center">
                        <UserIcon className="mr-3" />
                        <div>
                          <span className={classes.infoFont}>
                            {contact.name}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={3} className="flex flex-row items-center">
                        <div>
                          <Typography noWrap className={classes.infoTitle}>
                            {contact.title}
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item xs={3}>
                        <div className="w-full">
                          <div className="float-right">
                            <div className="flex flex-row items-center w-72">
                              <MessageIcon className="mr-3" />
                              <div className={classes.contactFont}>
                                {contact.mobilephone || i18n.t("sites.details.noPhoneNumber")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={3}>
                        <div className="w-full">
                          <div className="float-right">
                            <div className="flex flex-row items-center w-72">
                              <div className={classes.contactFont}>
                                {contact.email || i18n.t("sites.details.noEmail")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant="middle" className={classes.divider} />
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div>
          <Typography className={classes.titleFont}>{i18n.t("common.photos")}</Typography>
        </div>
        <div className={classes.grid}>
          <div className="flex flex-row items-center">
            {!!props?.images.length &&
              props?.images.map((img: any, index: any) => (
                <div key={index} className={classes.imgContainer}>
                  <img
                    className={classes.image + " cursor-pointer"}
                    onClick={() => openImage()}
                    alt="photo"
                    src={img}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      border: "1px solid #E0E0E0",
      borderRadius: "5px",
      margin: "5px",
      background: "#FFFFFF",
      padding: "15px",
    },
    titleFont: {
      fontSize: "33px!important",
      "font-weight": "800!important",
      lineHeight: "113%!important",
      color: "#0F2150!important",
      letterSpacing: "-1px!important",
    },
    subTitleFont: {
      fontSize: "20px!important",
      "font-weight": "800!important",
      lineHeight: "113%!important",
      color: "#0F2150!important",
      letterSpacing: "-1px!important",
      marginBottom: "20px",
      marginTop: "20px",
    },
    container: {
      maxWidth: "100%",
    },
    infoFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 15,
    },
    contactFont: {
      color: "#0F2150",
      fontWeight: "bold",
      fontSize: 13,
    },
    infoTitle: {
      fontSize: "13px",
      lineHeight: "113%",
      color: "gray",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    divider: {
      marginTop: "10px",
      marginBottom: "10px",
    },
    styleImg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    iframeBlock: {
      height: "93%",
      padding: "5px",
    },
    image: {
      width: "100%",
      height: "auto",
    },
    imgContainer: {
      position: "relative",
      width: "100%",
      maxWidth: "150px",
      minWidth: "150px",
      margin: "0px 10px",
    },
    imgButton: {
      background: "red",
      top: 0,
      right: 0,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      textAlign: "center",
      borderRadius: "5px",
      height: "25px",
      minWidth: "25px",
    },
    iframeBlockImg: {
      width: "60%",
      margin: "5px",
      backgroundColor: "white",
      position: "absolute",
    },
    carouselImg: {
      maxHeight: "85vh",
    },
  })
)

export default SiteDetailsCard
